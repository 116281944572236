html {
    color: var(--color-neutral-10);
    font-family: var(--font-family);
}

body {
    background-color: var(--color-background-body);
    font-size: var(--font-size-s);
}

form {
    display: flex;
    flex-direction: column;
}