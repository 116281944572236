.action-sheet{

    [class*=MuiPaper-root-MuiDrawer-paper]{
        background-color: transparent;
        padding: var(--space-base);
    }
}

.action-sheet-puller{
    margin-bottom: 0;
   

        .action-sheet-btn{
           
        }

        [class^=MuiButton]{
            color: var(--color-neutral-10);

            &:first-of-type{
                border-radius: var(--border-radius-soft);
                border: none;
                border-bottom-left-radius:0 ;
                border-bottom-right-radius:0 ;
                border-bottom: 1px solid var(--color-neutral-5)!important;
            }

            &:not(:first-child){
                border-radius: 0;
            }

            &:last-of-type{
                border:none;
                border-radius: var(--border-radius-soft);
                border-top-left-radius:0 ;
                border-top-right-radius:0 ;
            }

            &:only-child{
                border-radius: var(--border-radius-soft);
            }
            
            &.error{
                background-color: var(--color-neutral-0);
                border: none;
                color: var(--color-error);
            }
        }
}

.action-sheet-cancel{
    margin-top: var(--space-s);
    display: flex;
    justify-content: center;
}