.layout {
    .hamburguer-menu {
        display: none;
    }

    &-side {

        --side-menu-size-open: 300px;
        --side-menu-size-collapsed: 80px;
        --side-menu-size: var(--side-menu-size-open);
        --start-trans-time: .5s;
        --delay-trans-time: .3s;
        --calc-trans-time: calc(var(--start-trans-time) + var(--delay-trans-time));

        .layout-grid-container {
            //Verify
            max-width: 1280px;
            padding: var(--space-none) var(--space-l);
            margin: var(--space-none) auto;
            margin-left: var(--space-none);
            width: 100%;
        }

        @media (max-width: 620px) {
            .layout-grid-container {
                padding: var(--space-none) var(--space-base);
            }
        }

        @media (min-width: 621px) and (max-width: 820px) {
            .layout-grid-container {
                padding: var(--space-none) var(--space-m);
            }
        }
        

        .app-menu,
        .app-menu-overlay {
            display: none;
        }

        display: flex;
        flex-direction: row;
        min-height: 100vh;

        .app-menu-button-collapsible {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 26px;
            width: 26px;
            top: 30px;
            left: 100%;
            transform: translateX(-50%);
            border-radius: 50%;
            background-color: var(--color-neutral-0);
            box-shadow: var(--shadow-l);
            transition: scale 150ms ease-in-out, box-shadow 250ms ease-out;
            transform-origin: 0;
            cursor: pointer;
            z-index: 10;

            &:hover {
                scale: 1.2;
            }

            &:active {
                box-shadow: var(--shadow-l), 0 0 1px 1px var(--color-primary);
                transition: scale 150ms ease-in-out, box-shadow 0s;
            }

            i {
                color: var(--color-primary);
                font-family: 'fa-light-300';
                transition: transform 430ms ease-in-out;
            }
        }

        .app-menu__name {
            opacity: 1;
            transition: opacity 150ms ease;
            color: var(--color-neutral-7);
            font-size: var(--font-size-h6);
        }

        .app-menu__logo-image {
            opacity: 0;
            // transition: opacity 0ms ease-in 320ms;
            position: absolute;
            left: 0;
            top: 0;
            display: inline-flex;
            justify-content: center;
            width: calc(var(--side-menu-size-collapsed) - var(--space-m) * 2);
            transition: opacity 0.3s ease-in;
        }

        .header-logo {
            height: var(--header-size);
            padding: var(--space-none) var(--space-m);
            margin: var(--space-l) var(--space-none);

            margin-top: var(--space-l);
            margin-bottom: var(--space-s);
            position: relative;

            img {
                height: 22px;
            }

            &__image {
                display: inline-flex;
                position: relative;
                min-width: 270px;
            }
        }


        .app-menu__logo-image-full {
            display: inline-flex;
            transition: 0.3s ease-out, opacity 0.4s ease-in
        }

        // Web Layout Side Collapsible - Menu
        .app-menu-content {
            height: 100%;
            background-color: var(--color-neutral-0);
            bottom: 0;
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            height: auto;
            position: fixed;
            top: 0;
            width: var(--side-menu-size);
            transition: width 300ms ease;
            z-index: 105;
            box-shadow: 1px 0 5px 0 rgba(21, 24, 26, 0.1);
        }

        // Web Layout Side Collapsible - Menu - App Menu Link */
        .app-menu-links {
            flex: 1;
            height: 100%;
            display: flex;
            flex-direction: column;
            overflow-x: hidden;
            overflow-y: auto;
            position: relative;
            padding: var(--space-s) var(--space-m);
            padding-left: var(--space-s);
            padding-right: var(--space-none);

            span {
                font-size: var(--font-size-s);
                color: var(--color-neutral-7);
                opacity: 1;
                transition: opacity 250ms ease-in-out;
            }

            ul>div {
                padding-left: var(--space-m);

                .MuiTouchRipple-root {
                    display: none;
                }

                &:hover {
                    background-color: var(--color-neutral-0);

                    i {
                        color: var(--color-neutral-10);
                    }

                    svg {
                        fill: var(--color-neutral-10);
                    }

                    span {
                        color: var(--color-neutral-10);
                    }
                }
            }


            [class*=MuiListItemIcon-root] {
                min-width: var(--space-xl);
            }

            [class*=MuiCollapse-root] {

                .MuiCollapse-wrapper {
                    margin: var(--space-base) var(--space-none);
                }

                [class*=MuiList-root] {
                    &:before {
                        content: "";
                        position: absolute;
                        left: var(--space-s);
                        top: 50%;
                        transform: translateY(-50%);
                        width: 1px;
                        height: calc(100% - var(--space-s) * 2);
                        background-color: var(--color-neutral-3);
                    }

                }

                [class*=MuiListItemButton-root] {
                    margin-left: var(--space-s);
                    padding: var(--space-none);
                    padding-left: var(--space-base);

                    [class*=MuiListItemText-root] {
                        padding: var(--space-s) var(--space-base);
                        border-radius: var(--border-radius-medium);
                        margin: var(--space-none);
                    }


                    &:hover {
                        background-color: transparent;

                        [class*=MuiListItemText-root] {
                            background-color: var(--color-neutral-1);
                        }
                    }

                }
            }
        }

        .app-menu-close {
            z-index: 1;

            .desktop & {
                display: none;
            }

            .tablet & {
                left: var(--space-m);
                position: absolute;
            }

            .phone & {
                right: var(--space-base);
                position: absolute;
                width: 28px;

                &:before {
                    left: 0;
                }
            }
        }

        .app-login-info {
            padding: unset;
            overflow: visible;
            position: relative;
            border-bottom: var(--border-size-s) solid var(--color-neutral-3);

            [class*=MuiAvatar-circular] {
                box-shadow: 0 0 0 3px var(--color-neutral-0), 0 0 0 4px var(--color-primary);
                font-size: var(--font-size-base);
            }

            &__header {
                padding: var(--space-s) var(--space-m);
                font-size: var(--font-size-s);
                height: var(--header-size);
                margin: var(--space-s) var(--space-none);
            }

            &__user-info {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                position: relative;
                width: 100%;
                opacity: 1;
                transition: opacity 320ms ease-in-out;
                transition-delay: var(--calc-trans-time);

                span {
                    font-weight: 800;
                    color: var(--color-neutral-10);
                    font-size: var(--font-size-h6);
                }

                p {
                    font-size: var(--font-size-xs);
                }
            }

            &__submenu {
                margin: var(--space-base) var(--space-m);

                .MuiTypography-root {
                    font-size: var(--font-size-s);
                }

                .MuiListItemIcon-root {
                    min-width: var(--space-xl);
                }

                &>div {
                    padding-left: var(--space-s);

                    .MuiTouchRipple-root {
                        display: none;
                    }

                    span {
                        font-size: var(--font-size-s);
                        color: var(--color-neutral-7);
                    }

                    &:hover {
                        background-color: var(--color-neutral-0);

                        svg {
                            fill: var(--color-neutral-10);
                        }

                        span {
                            color: var(--color-neutral-10);
                        }
                    }
                }
            }


        }

        .menu-copy {
            height: var(--space-xl);
            display: flex;
            align-items: center;
            justify-content: center;
            transition: height 150ms ease, font-size 150ms ease;
        }

        &__header {
            //header section
            grid-row: 1;
            background-color: var(--header-color);
            box-shadow: 0 1px 5px 0 rgba(21, 24, 26, 0.1);
            z-index: 100;
            height: var(--header-size);
            position: fixed;
            width: 100%;
            display: none;

            &-container {
                display: flex;
                align-items: center;
                height: 100%;
            }

            &-application-name {
                margin-right: 80px;
                display: flex;
                align-items: center;
                font-size: var(--font-size-base);
                font-weight: var(--font-bold);

                img {
                    height: 28px;
                    margin-right: var(--space-base);
                }
            }

            &-menu {

                height: 100%;

                .app-menu-container {

                    flex: 1;
                    height: 100%;
                    display: flex;
                    align-items: center;

                    a {
                        margin-right: var(--space-m);
                        position: relative;
                        height: 100%;
                        display: flex;
                        align-items: center;
                        text-decoration: none;
                        color: var(--color-neutral-7);

                        &.is-active {
                            color: var(--color-primary);
                        }

                        &.is-active:before,
                        &:before {
                            content: "";
                            width: 100%;
                            height: 4px;
                            border-top-left-radius: 4px;
                            border-top-right-radius: 4px;
                            position: absolute;
                            bottom: -2px;
                            left: 0;
                            transition: all 150ms ease-in-out;
                            background-color: transparent;
                        }

                        &.is-active:before {
                            background-color: var(--color-primary);
                            bottom: 0px;
                        }

                        &:hover {
                            &:before {
                                background-color: var(--color-primary);
                                bottom: 0px;
                            }
                        }
                    }
                }
            }

        }

        &__main {
            display: flex;
            flex: 1;
            flex-direction: column;
            margin-left: var(--side-menu-size);
            transition: margin-left 300ms ease;
            min-width: 0;
        }

        &__content {
            //content section
            // overflow-y: auto;
            grid-row: 2;
            background-color: var(--color-background-body);

            .layout-grid-container {
                padding-top: var(--space-l);
                padding-bottom: var(--space-l);
            }

            @media (max-width: 620px) {
                .layout-grid-container {
                    padding-top: var(--space-base);
                    padding-bottom: var(--space-base);
                }
            }
    
            @media (min-width: 621px) and (max-width: 820px) {
                .layout-grid-container {
                    padding-top: var(--space-m);
                    padding-bottom: var(--space-m);
                }
            }

            .breadcrumbs {
                margin-bottom: var(--space-base);

                &:empty {
                    display: none;
                }
            }

            .content-top {
                display: flex;
                justify-content: space-between;

                h1,
                &__actions {
                    align-items: center;
                    display: flex;
                    flex-wrap: wrap;
                }

                h1:empty,
                &__actions:empty,
                &__title:empty {
                    display: none;
                }

                h1 {
                    flex: 1;
                    margin-bottom: var(--space-m);
                    font-size: var(--font-size-h1);
                }

                &__title {
                    flex: 1;
                    margin-bottom: var(--space-m);
                }

                &__actions {
                    flex: 1;
                    justify-content: flex-end;
                    margin-bottom: var(--space-m);
                    text-align: right;
                }
            }

            .main-content {
                position: relative;

                .layout-top--half-width & {
                    width: 50%;
                }

            }

        }

        &__footer {
            //footer section
            grid-row: 3;
            background-color: var(--color-background-body);
            padding-top: var(--space-base);
            padding-bottom: var(--space-base);
        }

        &--is-collapsed {
            --side-menu-size: var(--side-menu-size-collapsed);

            .app-menu-button-collapsible svg {
                transform: rotate(180deg);
            }

            & .header-logo {
                overflow: hidden;
                min-width: 32px;
            }

            & .app-menu__name {
                visibility: hidden;
                opacity: 0;
                min-width: 200px;
                white-space: nowrap;
                overflow: hidden;
            }

            .app-menu-links {
                .MuiButtonBase-root {

                    span,
                    &>svg {
                        opacity: 0;
                        transition: opacity 250ms ease-in-out;
                    }
                }

                .MuiCollapse-root {
                    display: none;
                }

            }



            .app-menu-content:not(:hover) {
                .app-menu__logo-image {
                    opacity: 1;
                    transition-delay: .1s;
                    transition: opacity 0.3s ease-in;
                }

                .app-menu__logo-image-full {
                    opacity: 0;
                    translate: 4px;
                }

                .app-login-info {
                    &__user-info {
                        opacity: 0;
                        width: 0;
                        transition: opacity 20ms ease-in-out;
                    }
                }

                .menu-copy {
                    font-size: 0;
                    height: 0;
                }
            }


        }

        &--is-hover-available {
            .app-menu-content:hover {
                --side-menu-size: var(--side-menu-size-open);
                transition-delay: var(--start-trans-time);



                .app-menu-links {

                    .MuiButtonBase-root {

                        span,
                        &>svg {
                            opacity: 1;
                            transition: opacity 750ms ease-in-out;
                        }
                    }

                    .MuiCollapse-root {
                        display: block;
                    }
                }

                .app-menu__name {
                    opacity: 1;
                    visibility: visible;
                    transition-delay: var(--start-trans-time);
                    transition-delay: var(--calc-trans-time);
                }

                .app-menu__logo-image,
                .app-menu__logo-image-full {
                    transition-delay: var(--start-trans-time);
                    transition-delay: var(--calc-trans-time);
                }

                .app-login-info__user-info {
                    opacity: 1;
                    transition-delay: var(--start-trans-time);
                }



                .menu-copy {
                    height: var(--header-size);
                    font-size: var(--font-size-s);
                    transition-delay: var(--calc-trans-time);
                }
            }
        }
    }

}

/* Tablet and Phone Devices */
@media (max-width: $tablet-width) {

    :root {
        --header-size: 56px;
    }

    .layout {

        &-grid-container {
            padding: var(--space-base);
        }

        &-side {

            aside {
                display: none;
            }

            //app header on mobile
            &__header {
                display: block;

                &-container {
                    justify-content: space-between;
                }

                &-menu,
                &-actions {
                    display: none;
                }

            }

            &__main {
                margin-left: 0;
            }

            &__content {
                padding-top: var(--header-size);

                .main-content {

                    .layout-top--half-width & {
                        width: 100%;
                    }

                }

            }

            //mobile app menu
            .app-menu {

                height: 100%;
                background-color: var(--color-neutral-0);
                bottom: 0;
                display: flex;
                justify-content: space-between;
                flex-direction: column;
                height: auto;
                position: fixed;
                top: 0;
                width: var(--side-menu-size);
                z-index: 105;
                left: calc(-1 * var(--side-menu-size));
                transform: translateX(0) translateZ(0);
                will-change: transform;

                &-overlay {

                    background-color: var(--overlay-background);
                    height: 100vh;
                    left: 0;
                    opacity: 0;
                    pointer-events: none;
                    position: fixed;
                    top: 0;
                    transition: opacity 130ms ease-in;
                    width: 100vw;
                    will-change: opacity;
                    z-index: 104;

                }

                &__header {

                    &-close {
                        width: 28px;
                        height: var(--header-size);
                        position: absolute;

                        right: var(--space-base);

                        i {
                            position: absolute;
                            font-size: var(--font-size-h4);
                            top: 50%;
                            translate: 0 -50%;
                            color: var(--color-primary);
                            cursor: pointer;
                            width: 28px;
                            display: inline-flex;
                            justify-content: center;
                        }
                    }
                }

                &__content {

                    flex: 1;
                    padding-top: var(--space-s);
                    margin-top: var(--space-xxl);

                    >ul {
                        padding: var(--space-s) var(--space-m);
                        padding-right: var(--space-none);
                        margin-bottom: var(--space-base);
                        position: relative;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        text-decoration: none;
                        color: var(--color-neutral-7);

                        .MuiTypography-root {
                            font-size: var(--font-size-s);

                        }

                    }

                    .app-menu-container {
                        display: flex;
                        flex-direction: column;

                        >ul {
                            div {
                                display: none;
                            }

                            padding: var(--space-s) var(--space-m);
                            margin-bottom: var(--space-base);
                            position: relative;
                            font-size: var(--font-size-s);
                            width: 190px;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            text-decoration: none;
                            color: var(--color-neutral-7);

                            // &.is-active {

                            //     color: var(--color-primary);

                            //     &:before {
                            //         content: "";
                            //         position: absolute;
                            //         top: 0;
                            //         left: 0;
                            //         width: 2px;
                            //         height: 100%;
                            //         background-color: var(--color-primary);
                            //     }
                            // }

                        }
                    }

                }

                &__content-bottom {
                    padding: var(--space-none);
                    color: var(--color-neutral-7);

                    [class^=MuiList-root] [class*=ListItemButton]:hover {
                        background-color: var(--color-neutral-0);
                    }
                }

                &__footer {
                    border-top: var(--border-size-s) solid var(--color-neutral-3);
                    height: var(--header-size);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }

            .app-menu-overlay {
                display: block;
            }

            &.app-menu-visible {
                .app-menu-overlay {
                    opacity: 1;
                    pointer-events: auto;
                }

                .app-menu {
                    transform: translateX(calc(-1 * var(--side-menu-size))) translateZ(0);
                    transition: all 300ms ease-in-out;
                    left: auto;
                    right: calc(-1 * var(--side-menu-size));
                }
            }

        }

    }

    .hamburguer-menu {
        border-bottom: 2px solid var(--color-neutral-10);
        border-top: 2px solid var(--color-neutral-10);
        position: relative;
        width: 20px;
        height: 20px;

        &:before {
            content: "";
            background-color: var(--color-neutral-10);
            height: 2px;
            position: absolute;
            width: 100%;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
        }
    }
}

/* Phone Portrait Only Styles */
@media (max-width: $phone-width) {

    .layout-side__header-application-name {
        margin-right: var(--space-base);
    }
}

/* Tablet Only Styles */
@media (min-width: $phone-width) and (max-width: $tablet-width) {

    .layout-side__header-application-name {
        margin-right: var(--space-m);
    }



}