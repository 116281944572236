@font-face {
    font-family: 'SofiaPro-Bold';
    src:  url('../resources/fonts/SofiaPro-Bold.woff') format('woff');
    font-weight: 700;
}

@font-face {
    font-family: 'SofiaPro-Medium';
    src:  url('../resources/fonts/SofiaProMedium.woff') format('woff');
    font-weight: 600;
}

@font-face {
    font-family: 'SofiaPro-Regular';
    src:  url('../resources/fonts/SofiaProRegular.woff') format('woff');
    font-weight: 400;
}

@font-face {
    font-family: 'SofiaPro-ExtraLight';
    src:  url('../resources/fonts/SofiaProExtraLight.woff') format('woff');
    font-weight: 300;
}