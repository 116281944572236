[class*=MuiChartsLegend-mark] {
    y:6;
    rx: 15;
    height: 10px;
    width: 10px;

}

.horizontalBarChart{

    .MuiChartsAxis-root.MuiChartsAxis-left .MuiChartsAxis-tickLabel {
        transform: translateX(15px);
        fill: white;
        text-anchor: start;
    }
}

.barChart{

    [class*=MuiResponsiveChart-container]{
        height:300px
    }

    [class*=MuiChartsSurface-root]{

        --ChartsLegend-labelSpacing:-5px;
        --ChartsLegend-rootSpacing: 20px;
        --ChartsLegend-rootOffsetY: 35px;
    }

}

.pieChart{

    [class*=MuiChartsLegend-label]{
        font-size: 8px;

    }

    [class*=MuiResponsiveChart-container]{
        display: flex;
       justify-content: center;
        width: 100%;
        height:400px
    }

    [class*=MuiChartsSurface-root]{
        --Charts-OffsetX:65px;
        --ChartsLegend-labelSpacing:-5px;
        --ChartsLegend-rootSpacing: -35px;
        --ChartsLegend-rootOffsetY:20px;
        --ChartsLegend-rootOffsetX:20px;
    }

}