.layout {

    &-login {

        display: flex;
        height: 100%;
        color: var(--color-neutral-0);

        &__left {
            background-color: var(--color-neutral-10);
            height: 100%;
            flex: 1;
            justify-content: flex-end;
            display: flex;
            align-items: center;
            position: relative;
            overflow: hidden;

            &-content {
                font-weight: var(--font-weight-bold);
                padding: 0 var(--space-xxl) 0 0;
                color: var(--color-neutral-0);
                z-index: 1;
                width: 60%;
            }

            &-title {
                font-size: 56px;
                line-height: 72px;
                position: relative;
                padding-bottom: var(--space-m);
                margin-bottom: var(--space-m);

                &:before {
                    content: "";
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    background-color: var(--color-primary);
                    height: 2px;
                    width: 48px;
                }
            }

            &-description {
                font-size: 20px;
                line-height: 26px;
            }

            &-background {
                z-index: 1;
                position: fixed;
                right: 50%;
                height: 100%;
                width: 50vw;

                img {
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                }
            }
        }

        &__right {
            background-color: var(--color-neutral-0);
            height: 100%;
            flex: 1;
            display: flex;
            align-items: center;
            color: var(--color-neutral-10);

            &-content {
                width: 320px;
                margin-left: 15%;
            }

            &-form {
                margin-top: 56px;
            }

            &-app-logo {
                margin-bottom: var(--space-xl);

                img {
                    height: 24px;
                }
            }

            &-app-name {
                font-size: var(--font-size-h1);
                font-weight: var(--font-bold);
                color: var(--color-neutral-10);
                margin-bottom: var(--space-l);
            }

            &-form-title {
                font-size: var(--font-size-h5);
                color: var(--color-neutral-8);
            }

        }

    }

}

/* Tablet and Phone Devices */
@media (max-width: $tablet-width) {
    .layout {

        &-login {
    
            display: flex;
            height: 100%;
            color: var(--color-neutral-0);
    
            &__left {
                display: none;
            }

            &__right {

                &-content {
                    flex: 1;
                }

                &-app-logo {
                    text-align: center;
                    margin-bottom: var(--space-xl);

                    img {
                        height: 20px;
                    }
                }
            }

            .MuiInputBase-root,
            button {
                width: 100%;
            }
            

        }
    }
}

/* Phone Portrate Only Styles */
@media (max-width: $phone-width) {

.layout {

    &-login {
        &__right {
                    
            &-content {
                padding: 0 var(--space-l);
            }
        }
    }
}

}

/* Tablet Only Styles */
@media (min-width: $phone-width) and (max-width: $tablet-width) {

    .layout {

        &-login {
            &__right {
                        
                &-content {
                    max-width: 300px;
                    padding: 0;
                    margin: 0 auto;
                }
            }
        }
    }

}