
[class*=MuiButtonGroup-root] {
     background-color: transparent;
     box-sizing: border-box;
    
    [class*=MuiButtonBase-root-MuiButton-root] {
          color: var(--color-neutral-7);
    
          &:hover {
               color: var(--color-primary);
               border: var(--border-size-s) solid var(--color-border);
          }
    }

    .MuiButtonGroup-grouped:not(:last-of-type) {
          border-right: var(--border-size-s) solid transparent;
          
          &:hover {
               border-right-color: var(--color-bg-hover);
          }
     
          &:focus {
               border-right-color: var(--color-border-focus);

               &:hover {
                    border: var(--border-size-s) solid var(--color-border-focus);
                    color: var(--color-neutral-7);              
               }
          }
    }

    .MuiButtonGroup-grouped:last-of-type {
          &:focus {
              
               &:hover {
                    border: var(--border-size-s) solid var(--color-border-focus);
                    color: var(--color-neutral-7);              
               }
          }
     }

     .MuiButtonGroup-grouped:not(:first-of-type) {
          margin-left: 0;
     }

     &.vertical {
          display: inline-flex;
          flex-direction: column; 

          [class^=MuiButton]{
               border: var(--border-size-s) solid var(--color-border);    
               
               &.primary {
                    border: var(--border-size-s) solid var(--color-primary); 
               }

               .lined & {
                    border: var(--border-size-s) solid transparent; 
               }
          }   
          
          .MuiButtonGroup-grouped:not(:last-of-type) {
               border-bottom-left-radius: 0;
               border-bottom-right-radius: 0;
               border-bottom: none;

               &:hover {
                    border-right-color: var(--color-border);
               }
          
               &:focus {
                    border: var(--border-size-s) solid var(--color-border-focus);
     
                    &:hover {
                         border: var(--border-size-s) solid var(--color-border-focus);
                         color: var(--color-neutral-7);              
                    }
               }

               &.primary:hover {
                    border: var(--border-size-s) solid var(--color-primary); 
                    color: var(--color-neutral-0);
               }
          }

          .MuiButtonGroup-grouped:not(:first-of-type) {
            margin-left: 0;
          }

          .MuiButtonGroup-grouped:first-of-type {
               border-top-right-radius: var(--border-radius-soft);
          }

          .MuiButtonGroup-grouped:last-of-type {
               border-top-right-radius: 0;
               border-bottom-left-radius: var(--border-radius-soft);

               &:focus {
                    border: var(--border-size-s) solid var(--color-border-focus);
                   
                    &:hover {
                         border: var(--border-size-s) solid var(--color-border-focus);
                         color: var(--color-neutral-7);              
                    }
               }

               &.primary:hover {
                    color: var(--color-neutral-0);
               }
          }

          &.lined {    
               [class^=MuiButton]{
                    border: var(--border-size-s) solid transparent;           
               }
          }
     }

     &.small {
          button[class^=MuiButton] {
               min-width: 32px;
               // width: 32px;
              height: 32px;
          }
      }
  
      &.large {
          button[class^=MuiButton] {
              height: 48px;
          }
      }
}

  



