//HORIZONTAL STEPPER

.MuiStepper-root {

    .MuiStep-root {

        .MuiStepLabel-label {
            font-size: var(--font-size-s);
            margin-top: var(--space-s);

            &.Mui-disabled {
                color: var(--color-neutral-7);
            }

            &.Mui-active {
                color: var(--color-primary);
            }
        }  

        .MuiStepLabel-iconContainer {
            
            svg {
                color:var(--color-neutral-0);
                font-size: 32px;
                border: 1px solid var(--color-neutral-5);
                border-radius: 20px;
                .MuiStepIcon-text{
                    font-size: 1rem;
                    fill:var(--color-neutral-7);
                }
            }

            &.Mui-active svg{
                color: var(--color-neutral-0);
                border: 1px solid var(--color-primary);
                border-radius: 20px;
                .MuiStepIcon-text{
                    fill:var(--color-primary);
                }

            }
            &.Mui-completed svg {
                border: 1px solid var(--color-primary);
                border-radius: 20px;
                color: var(--color-primary);
            }
        }

        .MuiStepConnector-root {
            top: 16px;
            left: calc(-50% + 16px);
            right: calc(50% + 16px);
            width: calc(100% - 32px);

            .MuiStepConnector-line {
                border-top-width: var(--border-size-m);
                color: var(--color-neutral-6);
            }
        }
    }

    &.small {

        .MuiStepLabel-iconContainer {

            svg {
                font-size: 8px;
            }

            .MuiStepIcon-text {
                display: none;
            }
        }

        .MuiStepConnector-root {
            top: 4px;
            left: calc(-50% + 7px);
            right: calc(50% + 8px);
            width: calc(100% - 16px);

            .MuiStepConnector-line {
                border-top-width: var(--border-size-m);
                color: var(--color-neutral-6);
            }
        }
    }
}

//VERTICAL STEPPER

.MuiStepper-root.MuiStepper-vertical {

    .MuiStep-root.MuiStep-vertical {

        .MuiStepLabel-root.MuiStepLabel-vertical {
            margin-top: 0; 
            padding: 0;  
            
            .MuiStepLabel-label {
                margin-top: 0;
                padding-left: var(--space-s);
            }
        }      
    }

    .MuiStepConnector-root.MuiStepConnector-vertical {
        margin-left: 16px;  
        height: 48px; 

        .MuiStepConnector-line.MuiStepConnector-lineVertical {
            border-left-width: var(--border-size-m);
            color: var(--color-neutral-6);
            height: 100%;
        }
    }

    &.small {        

        .MuiStepConnector-root.MuiStepConnector-vertical {
            margin: -3px 0 -3px 4px;  
            height: 48px; 
        }
    }
}

[class*=MuiMobileStepper-root]{
    background-color: transparent;
    box-shadow: none;
    padding: 8px 0 0 0;;

    .MuiMobileStepper-dotActive{
        background-color:var(--color-primary)
    }
}