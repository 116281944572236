.section-index{
    display: flex;
    flex-direction: column;
    position: sticky;
    top:32px;
    cursor: pointer;
    color: var(--color-neutral-7) ;
    border-left: 1px solid var(--color-neutral-5);
    align-self: flex-start;

    :active{
        border-left: 1px solid var(--color-primary);
        box-shadow: -1px 0 0 0 var(--color-primary) ;
        color: var(--color-neutral-9)
    }

    a:hover{
        color: var(--color-neutral-9)
    }
}
  