/* Date Picker */

.date-picker {

    .MuiSvgIcon-root {
        margin-right: var(--space-base);  
        font-size: var(--font-size-s);
        fill:var(--color-neutral-6)  
    }

    button[class^=MuiButton]:hover {
        background-color: transparent;
        box-shadow: none;
    }
} 