/*! Usefull - Typography */

$typography-sizes-values: (
    "display",
    "h1",
    "h2",
    "h3",
    "h4",
    "h5",
    "h6",
    "base",
    "s",
    "xs",
    "xxs"
);

$typography-weight-values: (
    "light",
    "regular",
    "semi-bold",
    "bold"
);

$typography-transforms-values: (
    "lowercase",
    "uppercase",
    "capitalize",
    "ellipsis"
);

body{

    // Font Sizes

    @each $value in $typography-sizes-values {
        .font-size-#{$value} {
            font-size: var(--font-size-#{$value});
        }
    }
}

// Font Weights
@each $value in $typography-weight-values {
    @if ($value == "bold") {
        .font-#{$value} {
            font-weight: var(--font-#{$value});
            font-family: "SofiaPro-Bold";
        }
    }

    @if ($value == "light"){
        .font-#{$value} {
            font-weight: var(--font-#{$value});
            font-family: "SofiaPro-ExtraLight";
        }
    }

    @if ($value == "semi-bold"){
        .font-#{$value} {
            font-weight: var(--font-#{$value});
            font-family: "SofiaPro-Medium";
        }
    }

    @if ($value == "regular") {
        .font-#{$value} {
            font-weight: var(--font-#{$value});
            font-family: "SofiaPro-Regular";
        }
    }
}       

// Text Transform
@each $value in $typography-transforms-values {
    @if ($value == "ellipsis") {
        .text-#{$value} {
            overflow: hidden;
            text-overflow: #{$value};
            white-space: nowrap;
        }
    } @else {
        .text-#{$value} {
            text-transform: #{$value};
        }
    }
}

// Line height

.font-size-base{
    line-height: 24px;
}

.font-size-s{
    line-height: 22px;
}

.font-size-xs{
    line-height: 18px;
}

.font-size-xxs{
    line-height: 14px;
}
.font-size-display{
    line-height: 46px;
}


body{
    /*! HTML Elements - Headings */

    h1, h2, h3, h4, h5, h6, .font-size-display {
        font-family: "SofiaPro-Medium" !important;
    }

    h1{
        line-height: 40px;
        font-size: var(--font-size-h1);
    }

    h2{
        line-height: 36px;
        font-size: var(--font-size-h2);
    }

    h3{
        line-height: 32px;
        font-size: var(--font-size-h3);
    }

    h4{
        line-height: 28px;
        font-size: var(--font-size-h4);
    }

    h5{
        line-height: 26px;
        font-size: var(--font-size-h5);
    }

    h6{
        line-height: 22px;
        font-size: var(--font-size-h6);
    }
}

// Tablet
@media (min-width: $phone-width) and (max-width: $tablet-width) {

    h1,
    .font-size-h1 {
        font-size: calc(var(--font-size-h1) - 2px);
        line-height: 34px;
    }

    h2,
    .font-size-h2 {
        font-size: calc(var(--font-size-h2) - 2px);
        line-height: 28px;
    }

    h3,
    .font-size-h3 {
        font-size: calc(var(--font-size-h3) - 2px);
        line-height: 24px;
    }

    h4,
    .font-size-h4 {
        font-size: calc(var(--font-size-h4) - 2px);
        line-height: 22px;
    }

    h5,
    .font-size-h5 {
        font-size: calc(var(--font-size-h5) - 2px);
        line-height: 20px;
    }

    h6,
    .font-size-h6 {
        font-size: calc(var(--font-size-h6) - 2px);
        line-height: 18px;
    }

    .font-size-display {
        font-size: calc(var(--font-size-display) - 2px);
        line-height: 40px;
    }
}

// Phone
@media (max-width: $phone-width) {

    h1,
    .font-size-h1 {
        font-size: calc(var(--font-size-h1) - 4px);
        line-height: 34px;
    }

    h2,
    .font-size-h2 {
        font-size: calc(var(--font-size-h2) - 4px);
        line-height: 28px;
    }

    h3,
    .font-size-h3 {
        font-size: calc(var(--font-size-h3) - 4px);
        line-height: 24px;
    }

    h4,
    .font-size-h4 {
        font-size: calc(var(--font-size-h4) - 2px);
        line-height: 22px;
    }

    h5,
    .font-size-h5 {
        font-size: calc(var(--font-size-h5) - 2px);
        line-height: 20px;
    }

    h6,
    .font-size-h6 {
        font-size: calc(var(--font-size-h6) - 2px);
        line-height: 18px;
    }

    .font-size-display {
        font-size: calc(var(--font-size-display) - 4px);
        line-height: 40px;
    }
}