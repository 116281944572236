[class*="MuiTableContainer-root"] {
  background-color: var(--color-neutral-0);

  [class*="MuiTable-root"] {
    border-radius: 100%;

    button[class*="MuiButton"] {
      background-color: transparent;
      border-color: transparent;
      width: 40px;

      &:hover {
        box-shadow: none;
        filter: none;
        border: 1px solid var(--color-border)
      }

    }

    [class*=MuiTableCell-root]{
        font-size: var(--font-size-body);

    }

    [class*="MuiTableHead-root"] {
      [class*="MuiTableRow-root"] {
        border-bottom: var(--border-size-s) solid var(--color-neutral-4);

        .MuiTableCell-root {
          color: var(--color-neutral-8);

          :hover {
            color: var(--color-primary);
          }

          .Mui-active {
            color: var(--color-primary);
            .MuiSvgIcon-root {
              color: var(--color-primary);            
            }
          }
        }
      }
    }

    [class*="MuiTableBody"] {
      [class*="MuiTableRow-root"] {
        &:nth-child(even) {
          background-color: var(--color-neutral-1);
        }

        &:hover {
          background-color: var(--color-bg-hover);
        }
      }

      .Mui-selected {
        background-color: inherit;
      }

      [class*="MuiTableCell"] {
        border: none;

        &:first-child {
          border-top-left-radius: var(--border-radius-soft);
          border-bottom-left-radius: var(--border-radius-soft);
        }
        &:last-child {
          border-top-right-radius: var(--border-radius-soft);
          border-bottom-right-radius: var(--border-radius-soft);
        }

        button[class*="MuiButton"] {
          .MuiSvgIcon-root {
            color: var(--color-primary);
            font-size: 1em;
          }
        }
      }
    }
  }

  [class*="MuiToolbar"] {
    button[class*="MuiButton"] {
      color: var(--color-neutral-7);
      border: var(--border-size-s) solid var(--color-border);
      border-radius: var(--border-radius-soft);
      height: 32px;
      width: 32px;
      margin-left: 8px;

      &.Mui-disabled {
        color: var(--color-neutral-4);
        background-color: var(--color-neutral-0);
      }

      .MuiTouchRipple-ripple {
        color: transparent;
      }
    }
  }
  .MuiPopover-root {
    .MuiPaper-root {
      padding: none;
    }
  }
}
