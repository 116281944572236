[class^="MuiList-root"] {
  padding: 0;

  .MuiSvgIcon-root {
    color: var(--color-neutral-7);
    font-size: var(--font-size-h4);
  }

  .MuiListItem-root {
    padding: 0;
  }

  [class*="ListItemButton"] {
    &:hover {
      background-color: var(--color-bg-hover);
    }

    &.Mui-selected {
      background-color: var(--color-bg-selected);

      &:hover {
        background-color: var(--color-bg-selected);
      }

      &:active {
        background-color: var(--color-bg-pressed);
      }

      .MuiSvgIcon-root {
        color: var(--color-primary);
      }
    }
  }
}