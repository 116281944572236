* {
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

html {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -servicestudio-overflow: hidden;
}

body {
    background-color: #000;
    direction: ltr;
    font-size: var(--font-size-body);
    line-height: 1.5;
    margin: 0;
}

html,
body {
    height: 100%;
}

#root {
    height: 100%;
}

.ph:empty,
.hidden {
    display: none;
}

:focus {
    outline: 0;
}

::-moz-focus-inner {
    border: 0;
}

abbr[title] {
    border-bottom: 0;
    text-decoration: underline;
}

div[onclick] {
    cursor: pointer;
}

h1,h2,h3,h4,h5,h6 {
    margin: 0;
}

::-webkit-file-upload-button {
    appearance: button;
}

select::-ms-expand,
input::-ms-clear {
    display: none;
}

::-webkit-scrollbar {
    width: 8px;
}

::-webkit-scrollbar-track {
    background: var(--color-neutral-4);
}

::-webkit-scrollbar-thumb {
    background-color: var(--color-neutral-6);
}
