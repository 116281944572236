/* :root {
    //--color-primary: #ff0000 !important; //change color if primary from UI is not good in LSG app
} */

/* Components page */

.components__wrapper {
    display: grid;
    grid-template-columns: auto auto auto auto;
    column-gap: var(--space-l);
    row-gap: var(--space-l);
    
    .components__category:empty {
        display: none;
    }

    .components__list a { 
        
        text-decoration: none;
        color: var(--color-primary);
        display: block;
        font-size: var(--font-size-base);
        line-height: 28px;

        &:hover {
            color: var(--color-primary-hover);
        }

        &:active {
            color: var(--color-secondary);
        }
    }  
}

// CodeBlock Snackbar (copied message)

.success-snackbar {
    &[class*=MuiPaper-root-MuiSnackbarContent-root] {
        min-width:150px !important;
        position: absolute;
        bottom: calc(100% + 10px);
        left: 50%;
        transform: translateX(290%);
        background-color: var(--color-success-light);
        color: var(--color-success);
        display: flex;
        align-items: center;
        padding: 8px 16px;
        border-radius: 4px;
        border-left:var(--border-size-l) solid var(--color-success);

        svg {
            margin-right: 8px;
        }
    }
}

@media only screen and (min-width: 320px) and (max-width: 480px) {
    .success-snackbar {
        &[class*=MuiPaper-root-MuiSnackbarContent-root] {
            transform: translateX(9%);
        }
    }
}

@media only screen and (min-width: 481px) and (max-width: 630px) {
    .success-snackbar {
        &[class*=MuiPaper-root-MuiSnackbarContent-root] {
            transform: translateX(17%);
        }
    }
}

@media only screen and (min-width: 641px) and (max-width: 960px) {
    .success-snackbar {
        &[class*=MuiPaper-root-MuiSnackbarContent-root] {
            transform: translateX(135%);
        } 
    }
}

@media only screen and (min-width: 961px) and (max-width: 1024px) {
    .success-snackbar {
        &[class*=MuiPaper-root-MuiSnackbarContent-root] {
            transform: translateX(220%);
        }
    }
}
  
.success-snackbar svg {
    margin-right: 8px;
}

.closeIcon {
    margin-right: 4px !important;
    font-size: var(--font-size-base);
    cursor: pointer;
}


/* Home Page */

.home__hero {
    width: 100%;
    height: 350px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
    margin-bottom: 50px;
    padding: var(--space-xl);

    .hero__img {
        height: 100%;
        object-fit:cover;
    }

    &-content {
        text-align: left;
        width: 400px;
        margin-right: 25%;

        p {
            margin: var(--space-l) 0;
            font-size: var(--font-size-h5);
        }
    }
} 

.home__cards {
    display: flex;
    flex-direction: row;
    margin-top: 50px;

    a {
        text-decoration: none;
        display: flex;
        flex-basis: 33%;  
        
        &:not(:last-of-type) {
            margin-right: var(--space-m);
        }
        
        .MuiCard-root {
            margin-bottom: var(--space-base);        

            &:hover {
                box-shadow: 0px 24px 40px rgba(36, 54, 129, 0.1);                   
            }            

            .MuiCardContent-root {
    
                svg {
                    background-color: var(--color-primary);
                    padding: var(--space-xs);
                    border-radius: var(--border-radius-soft);
                    margin-bottom: var(--space-s);
        
                    path {
                        fill: var(--color-neutral-0);
                    }
                }
            }
        }      
    }  
}

// Homepage - Discover Screen Templates 

.home__templates {
    margin-top: 60px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .home__description-column {
        p {
            display: flex;
            align-items: center;
            color: var(--color-neutral-7);
            font-size: var(--font-size-base);
            margin-bottom: var(--space-l);
        }
    }

    .home__picture-column {
        position: relative;   

        .img--top {
            position: absolute;
            width: 400px;
            z-index: 2;
            transform: perspective(100px) rotateY(-3deg);
            box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
            background-color: var(--color-neutral-1);

            img {
                height: 100%;
                width: 100%;
            }
        }

        .img--bottom {
            width: 400px;
            z-index: 1;
            margin: 40px 60px;
            transform: perspective(100px) rotateY(-3deg);
            box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;

            img {
                height: 100%;
                width: 100%;
            }          
        }
    }

    svg {
        margin-right: var(--space-s);
        fill: var(--color-neutral-10);
        width: 28px;
        height: 28px;
    }
}

//Home - bottom links
.home__bottom {
    margin-top: 50px;

    &-wrapper {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        justify-items: stretch;
        margin-top: var(--space-xxl);

        .home__bottom-links {
            display: flex;
            align-items: center;
            margin: 0 var(--space-xxl) var(--space-m) 0;

            h6 {
                font-size: var(--font-size-base);
                font-weight: var(--font-regular);
            }

            svg {
                margin-right: var(--space-s);
                font-size: 32px;        
            }
        }
    }
}

.button-link{
    text-decoration: none;
    color: var(--color-neutral-0);
}

.target-link{
    text-decoration: none;
    color: var(--color-primary);

    &:hover {
        color: var(--color-primary-hover);
    }
}

/* Get Started Page */

.get-started {
    h4 {
        margin: 0 0 var(--space-base) 0;
    }

    h6 {
        margin: var(--space-base) 0 var(--space-s) 0;
    }

    p {
        margin: 0;
    }

    ol,
    ul {
        margin-bottom: 0;
    }

    li::marker {
        font-size:var(--space-base);
        font-weight: var(--font-bold);
    }
}


/* Styles Page */

.styles-table {
    [class*=MuiTable] [class*=MuiTableBody] [class*=MuiTableCell] {
        color: var(--color-secondary);
        background-color: var(--color-neutral-0);
    }

    .MuiTableHead-root {
        .MuiTableRow-head :last-child {
            padding-left: 0;
        }
    }

    .MuiImageList-root {
        margin: 0;
    }
    
    .MuiImageListItem-root {
        width: 100px;
        height: 100px;
    }
}   

.preview-box {
    height: 32px;
    background-color: var(--color-neutral-6);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: var(--color-primary);

    &.rectangle {
        width: 64px;
    }
    
    &.square {
        width: 32px;

        &--small {
            width: 20px;
            height: 20px;

            &.absolute-top-plus-header {
                top: 20px;
            }
        }
    }

    &.border-size {
        border-color: var(--color-primary);
    }

    &.no-background {
        background-color: var(--color-neutral-0);
        border: var(--border-size-s) solid var(--color-border);
    }

    &.block {
        display: block;
    }
}
    
.preview-background {
    background-color: var(--color-primary);
    width: fit-content;
    line-height: 0;
}

.preview-display {
    background-color: var(--color-neutral-3);
    height: 150px;
    width: 300px;
    padding: var(--space-base);
    position: relative;

    &.block {
        display: block;
    }

    &-box {
        width: 100px;
        height: 50px;
        border: var(--border-size-m) solid var(--color-primary);
        background-color: var(--color-neutral-6);
        margin-bottom: var(--space-s);
        margin-right: var(--space-s);
    }

    &--small {
        background-color: var(--color-neutral-3);
        width: 300px;
        height: 50px;
    }

    &--square {
        background-color: var(--color-neutral-3);
        width: 150px;
        height: 150px;
        position: relative;
        overflow-y: auto;

        &-bar {
            width: 70%;
            background: var(--color-neutral-4);
            color: var(--color-neutral-10);
        }

        &-header {
            display: flex;
            align-items: center;
            background-color: var(--color-primary);
            color: var(--color-neutral-0);
            justify-content: center;
        }
    }

    span {
        top: 40%;
        left: 50%;
        transform: translate(-50%);
        position: absolute;
        color: var(--color-primary);
    }

    .btn {
        background-color: var(--color-primary);
        color: var(--color-neutral-0);
        top: 10px;
        left: 10px;
        padding: var(--space-s);
        width: 80px;
        text-align: center; 
        
        &.sticky {
            top: 0;
            margin-top: 30px;
            display: flex;
            align-items: center; 
            justify-content: center;
        }
       
        &.fixed {
            display: flex;
            align-items: center; 
            justify-content: center;
            position: sticky;
            top: 0;
            margin-top: 0;
        }
    }

    &.transition {
        transition: all 0.33s ease-in-out 0s;       

        &:hover {
            transform: scaleX(0.9);
            transition: all 0.33s ease-in-out 0s;
            background-color: var(--color-primary);
            color: var(--color-neutral-0);
        }
    }
    
    &.transform {
        transition: all 0.33s ease-in-out 0s; 

        &:hover {
            transform: translate(-10px, -10px);
            transition: all 0.33s ease-in-out 0s;
            background-color: var(--color-primary);
            color: var(--color-neutral-0);
        }
    }
}


/* Live Style Guide Components*/

.frame-component {
    background-color: var(--color-neutral-4);
    padding: 16px;
    width: 500px;
}

.code-preview {
    background-color: var(--color-neutral-9);
    border-radius: var(--border-radius-soft);
    padding: var(--space-base);
    padding-right: 100px;
    position: relative;
    box-shadow: var(--shadow-m);

    &-button {
        padding: 8px;
        text-transform: none;
        font-size: var(--font-size-xxs);
        background-color: var(--color-neutral-0);
        color: var(--color-primary);
        border: var(--border-size-none) solid var(--color-neutral-5);
        transition: filter 200ms ease-in-out;
        position: absolute;
        right: var(--space-base);
        top: var(--space-base);
        overflow: hidden;
        border-radius: var(--border-radius-soft);

        &:hover {
            background-color: var(--color-bg-hover);
            box-shadow: rgba(170, 180, 190, 0.3) 0px 4px 20px;
            cursor: pointer;
        }
    }

    pre { //we need importants because of inline CSS added by the component

        margin: 0;
        border-radius: var(--border-radius-soft);

        &::-webkit-scrollbar {
            height: 8px;
        }
    
        &::-webkit-scrollbar-track {
            background: var(--color-neutral-9);
        }
        
        &::-webkit-scrollbar-thumb {
            background-color: var(--color-neutral-7);
        }


    }
}

/* Component Preview Card */

.component-preview {
    
    margin-bottom: var(--space-xxl);

   
    &__description {
        margin-top: var(--space-s);
        font-size: var(--font-size-h6);
        color: var(--color-neutral-7);
    }


    &__component {
        margin-top: var(--space-base);
        background-color: var(--color-neutral-0);
        border-radius: var(--border-radius-soft);
        padding: var(--space-base);
        box-shadow: var(--shadow-m);
    }

    &__code {
        margin-top: var(--space-l);
    }

    &__title:empty,
    &__subtitle:empty,
    &__description:empty,
    &__code:empty,
    &__component:empty {
        display: none;
    }
}

/* Screen Template Preview Card */

.screen-template {

    &-list {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-column-gap: var(--space-l);
        grid-row-gap: var(--space-l);

        a {
            text-decoration: none;
            color: var(--color-neutral-10);

            &:active {
                color: var(--color-neutral-10);
            }
        }
    }

    &-preview {   
        
        height: 100%;

        &__image {
            overflow: hidden;
            // height: 200px;
            margin-bottom: var(--space-base);
            transition: box-shadow 250ms ease-out;
            border: 1px solid var(--color-neutral-4);

            img {
                width: 100%;
                object-fit: cover;
                transition: all 150ms ease-out;
            }
        }

        &__title {
          
            font-weight: var(--font-semi-bold);
            margin-bottom: var(--space-s);
        }

        &__description {
            font-size: var(--font-size-xs);

        }

        &:hover {

            .screen-template-preview__image {
                box-shadow: 0px 24px 40px rgba(36, 54, 129, 0.1);

                img {
                    transform: scale(1.02);
                    
                }
            }

        }

    }

}

/* Utilities to control width measures */


.width-150 { width: 150px; }
.width-300 { width: 300px; }
.width-500 { width: 500px; }
.height-100 { height: 100px; }
.height-150 { height: 150px; }
.height-300 { height: 300px; }
.height-400 { height: 400px; }
.height-500 { height: 500px; }

.min-width-150 { min-width: 150px; }
.min-width-300 { min-width: 300px; }
.min-height-350 {min-height: 350px;}

.not-desktop-sidebar{
  max-width: 85vw;
}

.svg-resize{
    [class*=MuiSvgIcon-root] {
        font-size: 120px !important;
    }
}

